import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import CTA from "../../../components/cta"
import ServiceAreas from "../../../components/serviceAreas"
import SubServicesAlt from "../../../components/subServicesAlt"
import Education from "../../../components/education"
import HeroSectionAlt from "../../../components/heroSectionAlt"
import Testimonials from "../../../components/testimonials"

function WashMachineDrain() {
  const data = useStaticQuery(graphql`
    query washMachineDrainImages {
      washingMachineDrain: file(
        relativePath: { eq: "washing-machine-drain-repair.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Washing Machine Drain Repair"
        description={`If your wachine machine drain is clogged, we can fix it! With over 30 years of residential plumbing experience, so we'll unclog your shower drain quickly.`}
      />
      <HeroSectionAlt
        h1={`Washing Machine Drain Repair`}
        h2={`Have a Master Plumber Repair Your Washing Machine Drain`}
        h3={`Do you have a slow-draining washing machine? When your wachine machine can't drain properly, it can lead to dirty or standing water in your appliance.`}
        p1={`Washing machine drains often get clogged by soap residue, fibers, grease, and oils that come off your clothes. A properly-functioning washing machine means your clothes can get as clean as possible. Talk with our master plumber for a free quote to get your drain free-flowing again.`}
        heroImg={data.washingMachineDrain.childImageSharp.fluid}
        heroImgAltTag={`Washing Machine Drain Repair`}
      />
      <Education
        mainTitle={`Signs Your Washing Machine Needs Repair`}
        titleOne={`Slow-Draining Machine`}
        descriptionOne={`If your washing machine drains slowly or has standing water after a cycle, your clothes can't get as clean as possible. This is a good indicator that your drainage system has a blockage somewhere.`}
        titleTwo={`Still-Dirty Clothes`}
        descriptionTwo={`When your washing machine can't drain properly, your clothes will suffer. If you notice your regular process isn't getting your clothes as clean as normal, your washing machine drain might need repair.`}
        titleThree={`Water Leaks`}
        descriptionThree={`Your washing machine shouldn't be leaking. If you notice water build-up around your appliance, it could be that a hose isn't functioning like it's supposed to. This could be a cause of a slow-draining machine as well.`}
      />
      <SubServicesAlt
        mainTitle={`Our Washing Machine Services`}
        titleOne={`Drain Blockage Diagnosis`}
        descriptionOne={`Our team is highly experienced in diagnosing and locating the blockage in your washing machine drain.`}
        titleTwo={`Breaking Up the Clog`}
        descriptionTwo={`Once we've found the cause of the blockage, we can remove the clog using proven methods and precision tools.`}
        titleThree={`Offer Advice`}
        descriptionThree={`Nobody likes a leaky washing machine, so once we're done with repairs, we'd love to offer you advice about how to prevent the same issue in the future`}
      />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <ServiceAreas
        description={`washing machines`}
        subDescription={`washing machine drain repair`}
      />
      <CTA
        title={`Get a Free Quote to Fix Your Drain Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default WashMachineDrain
